<template>
  <div class="input-data">
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">Purchase Order</h2>
    <div class="input-data">
      <div>
        <section>
          <ValidationObserver ref="form">
            <div class="b-table custom-table">
              <div class="table-wrapper has-sticky-header">
                <table class="table is-bordered">
                  <thead>
                  <tr class="header-row">
                    <th>{{ $t('warehouse.product_name') }}</th>
                    <th>{{ $t('category') }}</th>
                    <th>{{ $t('warehouse.qty') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, key) in data" :key="`data-${key}`">
                    <td class="p-0">
                      <b-field class="m-0">
                        <b-input
                            v-if="isLocal"
                            v-model="item.name"
                            @input="value => item.product.name = value"
                            class="w-100"
                        />
                        <b-autocomplete
                            v-else
                            clearable
                            append-to-body
                            open-on-focus
                            keep-first
                            select-on-click-outside
                            :data="filteredProduct"
                            :placeholder="$t('search')"
                            field="name"
                            v-model="item.name"
                            class="w-100"
                            @typing="searchProduct"
                            @select="option => handleSelectProduct(option, item)"/>

                        <div class="is-flex is-align-items-center pr-3">
                          <b-button type="is-primary" icon-left="plus" size="is-small" class="ml-4 mr-2"
                                    @click="addData(item, key + 1)"></b-button>
                          <b-button type="is-primary" icon-left="minus" size="is-small" @click="deleteData(key)"
                                    :disabled="data.length === 1"></b-button>
                        </div>
                      </b-field>
                    </td>
                    <td class="p-0">
                      <b-field class="m-0">
                        <ValidationProvider :name="`${$t('category')} ${key + 1}`"
                                            :rules="{
                                              'required': !!(item.product && item.product.name)
                                            }"
                                            v-slot="{ errors }" v-if="isLocal">
                          <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                            <sg-select type="is-secondary is-light"
                                       no-outline
                                       expanded
                                       :items="warehouseCategory"
                                       label="category_name"
                                       v-model="item.category"
                                       :placeholder="$t('select') + ' ' + $t('category')"></sg-select>
                          </b-field>
                        </ValidationProvider>
                        <b-input v-model="item.category_name" disabled v-else/>
                      </b-field>
                    </td>
                    <td class="p-0">
                      <b-field class="m-0">
                        <ValidationProvider class="w-100" :name="`${$t('warehouse.qty')} ${key + 1}`"
                                            :rules="{
                                              'required': !!(item.product && item.product.name)
                                            }"
                                            v-slot="{ errors }">
                          <b-field class="w-100 m-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                            <b-input v-model.number="item.qty" type="number" step="any"
                                     :placeholder="`Input ${$t('warehouse.qty')}`" class="w-100"/>
                          </b-field>
                        </ValidationProvider>
                        <p class="control">
                          <sg-select type="is-secondary is-light" no-outline expanded :items="unit"
                                     v-if="item.product || isLocal"
                                     class="select-unit"
                                     append-to-body
                                     :disabled="!isLocal"
                                     v-model="item.product.unit" :placeholder="`Unit`"></sg-select>
                        </p>
                      </b-field>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ValidationObserver>
        </section>

        <footer class="is-flex is-justify-content-flex-end mt-3">
          <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
        </footer>
      </div>
    </div>

    <b-table :mobile-cards="false" :data="purchaseOrders">
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>
      <b-table-column field="product_name" :label="$t('warehouse.product')" v-slot="props">
        <div class="is-flex is-justify-content-space-between" v-for="(po, key) in props.row.product_purchase_order" :key="key">
          <span class="has-text-weight-bold">{{ po.name }}</span> [{{ po.qty }} {{ po.unit }}]
        </div>
      </b-table-column>
      <!--            {{ $number(props.row.qty) }} {{ props.row.unit }}-->
      <b-table-column v-slot="props">
        <div class="is-flex is-justify-content-flex-end">
          <b-button type="is-success" class="mr-2" @click="sendPo(props.row.po_id)" icon-left="whatsapp"></b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-pagination
        v-show="totalItem"
        class="pagination-borderless my-5"
        :total="totalItem"
        v-model="page"
        @change="getData"
        order="is-centered"
        :per-page="10"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
      <b-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page">
        Prev
      </b-pagination-button>

      <b-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page">
        Next
      </b-pagination-button>
    </b-pagination>

  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapActions, mapGetters, mapState} from "vuex";
import debounce from "lodash/debounce";

export default {
  name: "InputPurchaseOrder",
  components: {SgSelect},
  computed: {
    ...mapGetters('farm', [
      'isPro',
      'unit',
      'warehouseCategory',
    ]),

    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },

    embed() {
      return this.$route.query.output === 'embed'
    },

    ...mapGetters([
      'isLocal',
    ]),
  },
  data: () => {
    return {
      purchaseOrders: [],
      page: 1,
      totalItem: 0,

      data: [],

      filteredProduct: [],
      products: [],

      isFetching: false,
      isDeleting: false,
    }
  },
  created() {
    this.resetForm()
    this.getData()
  },
  methods: {
    ...mapActions('farm', [
      'addPurchaseOrder',
      'getPurchaseOrder',
      'sendPurchaseOrderToWhatsapp',
    ]),

    sendPo(po_id) {
      this.$loading()
      this.sendPurchaseOrderToWhatsapp(po_id).finally(() => {
        this.$loading(false)
      })
    },

    getData() {
      this.$loading()
      this.getPurchaseOrder({
        page: this.page,
      }).then(res => {
        if (!res.error && res.data && res.data.data && res.data.data.length) {
          this.purchaseOrders = res.data.data
          this.totalPage = res.data.paging.total_page
          this.totalItem = res.data.paging.total_page * 10
        }
      }).finally(() => {
        this.$loading(false)
      })
    },

    addData(item, key) {
      this.data.splice(key, 0, {product: {}})
    },

    deleteData(key) {
      this.data.splice(key, 1)
      this.isDeleting = true
    },

    ...mapActions('farm', [
      'getProducts',
    ]),

    resetForm() {
      this.data = this.isLocal ? [{product: {}}, {product: {}}, {product: {}}, {product: {}}, {product: {}}] : [{}, {}, {}, {}, {}]
    },

    handleSelectProduct(option, item) {
      if (this.isDeleting) {
        this.isDeleting = false
        return
      }
      item.product = option
      if (item.product) {
        let category = this.warehouseCategory.find(e => e.category_id === item.product.category_id)
        item.category_name = category.category_name

        item.name = item.product.name
        if (!item.product.unit) item.product.unit = 'Kg'
      } else {
        item.qty = null
        item.category_name = null
      }
      this.filteredProduct = []
      this.$refs['form'].reset()
    },

    async handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          let products = this.data.filter(e => e && e.product && e.product.name).map(e => {
            return {
              qty: e.qty,
              product_id: e.product.product_id,
              category_id: this.isLocal ? (e.category ? e.category.category_id : null) : e.product.category_id,
              price: e.product.fix_price,
              name: e.product.name,
              photo: e.product.photo,
              unit: e.product.unit,
            }
          })

          if (products.length === 0) return

          this.$loading()

          let payload = {
            farm_id: this.selectedFarm.farm_id,
            price: products.reduce((a, b) => {
              a += b.price * b.qty
              return a
            }, 0),
            product_purchase_order: products,
          }

          let res = await this.addPurchaseOrder(payload)

          if (!res.error) {
            if (this.isLocal) {
              this.page = 1
              this.getData()
            } else {
              this.$buefy.dialog.alert({
                title: 'Success',
                message: 'Berhasil melakukan Purchase Order, silahkan mengunjungi <a href="https://supply.sgara.id">https://supply.sgara.id</a> untuk melakukan konfirmasi order',
                confirmText: 'OK'
              })
              this.$router.push('/farm/' + this.selectedFarm.farm_id)
            }
            this.resetForm()
          }

          this.$loading(false)
        }
      })
    },

    searchProduct: debounce(function (name) {
      this.filteredProduct = []
      if (!name.length) {
        return
      }
      this.isFetching = true
      this.getProducts({
        search: name,
      }).then((res) => {
        this.filteredProduct = res.data
      }).finally(() => {
        this.isFetching = false
      })
    }, 500)
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>

<style lang="scss">
.input-data {
  .button.is-small {
    padding: 0.05rem 0.75rem;
  }

  .select-unit {
    min-width: unset !important;
    width: 100px;
  }
}
</style>
